export const gotoAlias = {
  '/goto/linkedin': 'https://www.linkedin.com/in/holtwick/',
  '/goto/xing': 'https://www.xing.com/profile/Dirk_Holtwick',
  '/goto/github': 'https://github.com/holtwick',
  '/goto/stackoverflow': 'https://stackoverflow.com/users/140927/holtwick',
  '/goto/newsletter': 'https://newsletter.holtwick.de/subscription/form', // https://apperdeck.us3.list-manage.com/subscribe?u=621baeb76a5231f2c741ac28c&id=666597182a',

  '/goto/x-holtwick': 'https://x.com/holtwick',
  '/goto/x-apperdeck': 'https://x.com/apperdeck',
  '/goto/x-dirkholtwick': 'https://x.com/dirkholtwick',
  '/goto/x-receipts': 'https://x.com/receipts_app',

  '/goto/blog-holtwick': 'https://holtwick.de/blog',
  '/goto/blog-receipts': 'https://receipts-app.com/blog',

  '/goto/facebook-receipts': 'https://www.facebook.com/receiptsapp/',

  '/goto/youtube': 'https://www.youtube.com/@dirkholtwick',
  '/goto/youtube-receipts': 'https://www.youtube.com/channel/UCrRr0xEJq8EuvTMtndlszWQ',

  '/goto/setapp': 'https://go.setapp.com/stp251?refAppID=351&utm_medium=vendor_program&utm_content=button',

  '/goto/producthunt-pdfify': 'https://www.producthunt.com/posts/pdfify',
  '/goto/producthunt-receipts': 'https://www.producthunt.com/posts/receipts',
  '/goto/producthunt-briefing': 'https://www.producthunt.com/posts/briefing-2',

  '/goto/alternativeto-pdfify': 'https://alternativeto.net/software/pdfify/about/',
  '/goto/alternativeto-receipts': 'https://alternativeto.net/software/receipts/about/',
  '/goto/alternativeto-briefing': 'https://alternativeto.net/software/brie-fi-ng/about/',

  '/goto/macupdate-pdfify': 'https://www.macupdate.com/app/mac/60862/pdfify',
  '/goto/macupdate-receipts': 'https://www.macupdate.com/app/mac/57750/receipts',

  '/goto/appstore-pdfify': 'https://apps.apple.com/app/apple-store/id1435055351?pt=21007&ct=homepage&mt=8', // 'https://apps.apple.com/app/pdfify/id1435055351',
  '/goto/appstore-receipts': 'https://apps.apple.com/app/id1079833326',

  '/goto/receipts': 'https://receipts-app.com', // 'https://apps.apple.com/app/id1079833326',

  '/goto/github-receipts': 'https://github.com/holtwick/receipts-api',
  '/goto/github-pdfify': 'https://github.com/holtwick/pdfify',
  '/goto/github-briefing': 'https://github.com/holtwick/briefing',

  '/goto/pdfify-issues': 'https://github.com/holtwick/pdfify/issues/',
  '/goto/pdfify-bug': 'https://github.com/holtwick/pdfify/issues/new?assignees=&labels=bug_report&template=bug_report.md&title=',
  '/goto/pdfify-feature': 'https://github.com/holtwick/pdfify/issues/new?assignees=&labels=feature_request&template=feature_request.md&title=',
}

// Briefing
// 'producthunt' : 'https://www.producthunt.com/posts/briefing-2',
// 'appstore-windows' : 'https://www.microsoft.com/p/briefings/9pcs356fc2jf',
// 'pwa' : 'https://developer.mozilla.org/en-US/docs/Web/Progressive_web_apps/Developer_guide/Installing',
// 'alternative-to' : 'https://alternativeto.net/software/brie-fi-ng/'
// 'issues' : 'https://github.com/holtwick/briefing/issues/',
// 'bug' : 'https://github.com/holtwick/briefing/issues/new?assignees=&labels=bug_report&template=bug_report.md&title=',
// 'feature' : 'https://github.com/holtwick/briefing/issues/new?assignees=&labels=feature_request&template=feature_request.md&title=',
